<template>
    <div class="view-page">
        <!-- 基础信息 -->
        <div class="basic">
            <el-button
                type="primary"
                @click="firstExportBtn()"
                >导出pdf</el-button
            >
            <div class="view-title">
                <div class="view-flag"></div>
                <div class="title">个人信息</div>
            </div>
            <el-form
                :model="basicForm"
                :rules="basicRules"
                ref="basicForm"
                label-position="right"
                label-width="120px"
                :disabled="pageType == 'view'"
            >
                <div class="basic-form basic-form-view">
                    <div class="basic-info">
                        <el-row>
                            <el-col :span="12">
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-name baseCol-checked"
                                        >
                                            姓名
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="baseCol baseCol-student">
                                            {{ basicForm.studentName }}
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-studyNo baseCol-checked"
                                        >
                                            性别
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div
                                            class="baseCol baseCol-studyNo baseCol-sex"
                                        >
                                            <div v-if="basicForm.sex == 1">
                                                男
                                            </div>
                                            <div v-else>女</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-id baseCol-checked"
                                        >
                                            身份证号
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="baseCol baseCol-idCard">
                                            <span
                                                v-if="basicForm.idCard"
                                                :style="
                                                    basicForm.idCard.indexOf(
                                                        '*',
                                                    ) === -1
                                                        ? {color: '#3672d9'}
                                                        : {}
                                                "
                                                >{{
                                                    basicForm.idCard || ""
                                                }}</span
                                            >
                                            <i
                                                v-has-permi="[
                                                    'studentBase:desensitized',
                                                ]"
                                                v-if="basicForm.idCard"
                                                class="el-icon-view"
                                                :class="{'is-closed': basicForm.idCard.indexOf('*') > 0}"
                                                :style="
                                                    basicForm.idCard.indexOf(
                                                        '*',
                                                    ) === -1
                                                        ? {color: '#3672d9'}
                                                        : {}
                                                "
                                                @click="desensitizeGet"
                                            ></i>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-img baseCol-checked"
                                        >
                                            头像
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="baseCol-user">
                                            <el-image
                                                class="showImage"
                                                :src="basicForm.stuImg || avatar"
                                                :preview-src-list="[basicForm.stuImg || avatar]"
                                            >
                                            </el-image>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-studyNo baseCol-checked"
                                >
                                    班级
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div
                                    class="baseCol baseCol-studyNo baseCol-idCard"
                                >
                                    {{ basicForm.organName }}
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-idCard baseCol-checked"
                                >
                                    学号
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-idCard">
                                    {{ basicForm.studyNo }}
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-studyNo baseCol-checked"
                                >
                                    唯一号
                                </div>
                            </el-col>
                            <el-col :span="20">
                                <div class="baseCol baseCol-idCard">
                                    {{ basicForm.onlyCode }}
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-studyNo baseCol-checked"
                                >
                                    序列号
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-idCard">
                                    {{ basicForm.serialNumber }}
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-idCard baseCol-checked"
                                >
                                    消费账号
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-idCard">
                                    {{ basicForm.consumNumber }}
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-studyNo baseCol-checked"
                                >
                                    学生类型
                                </div>
                            </el-col>
                            <el-col :span="20">
                                <div class="baseCol baseCol-idCard">
                                    {{
                                        basicForm.studentType
                                            | studentTypeFilter
                                    }}
                                </div>
                            </el-col>
                        </el-row>
                        <div class="archivesCompleteness">
                            <img
                                class="archivesCompleteness-icon"
                                :src="archivesCompletenessUrl"
                            />
                            <div class="archivesCompleteness-title">
                                档案填写进度
                            </div>
                            <div class="archivesCompleteness-value">
                                {{ basicForm.archivesCompleteness }}%
                            </div>
                        </div>
                        <el-progress
                            :percentage="basicForm.archivesCompleteness"
                            :show-text="false"
                        ></el-progress>
                    </div>
                </div>
            </el-form>

            <div
                v-if="oriBasicForm.id"
                v-show="detailFormShow"
                class="detail-form"
            >
                <stu-info-fill
                    ref="stuInfoFill"
                    :mode="pageType"
                    :school-id="schoolId"
                    :stu-basic-info="oriBasicForm"
                    @getTemplateList="getTemplateList"
                />
            </div>
        </div>

        <dialog-image :dialogImageObj="dialogImageObj"  @closed="closedBtn" ></dialog-image>
    </div>
</template>

<script>
import {StuInfoFill} from "common-local";
import DialogImage from "@/components/scrollWrapper/Sub/DialogImage.vue";

export default {
    name: "ViewPage",
    components: {
        StuInfoFill,
        DialogImage,
    },
    props: [
        "basicForm",
        "basicRules",
        "pageType",
        "avatar",
        "archivesCompletenessUrl",
        "oriBasicForm",
        "detailFormShow",
        "schoolId",
        "tabList",
        "exportDialog",
    ],
    data() {
        return {
            dialogImageObj: {
                show: false,
                url: ""
            }
        };
    },
    filters: {
        studentTypeFilter(val) {
            let map = {
                0: "住宿",
                1: "走读",
                2: "半读",
            };
            return val && map[val] ? map[val] : "";
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        bigShow(url){
            this.dialogImageObj.url = url;
            this.dialogImageObj.show = true;
        },
        closedBtn(){
            this.dialogImageObj = {
                show: false,
                url: ""
            }
        },
        desensitizeGet() {
            this.$emit("desensitizeGet");
        },
        getTemplateList(value) {
            this.$emit("getTemplateList", value);
        },
        firstExportBtn() {
            this.$emit("firstExportBtn", true);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-cascader .el-input .el-icon-circle-close,
    .el-input.el-input--small .el-input__inner,
    .el-select .el-input .el-input__inner {
        line-height: 36px;
        height: 36px;
        border-radius: 0;
    }
}
.view-page {
    height: calc(100vh - 132px);
    max-width: 960px;
    // margin: 0 auto;
    overflow-y: auto;
}
// 隐藏滚动条且可滚动
.view-page::-webkit-scrollbar {
    width: 0 !important;
}
// 基础信息
.basic {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    margin: 0 auto;
    width: 920px !important;
}
::v-deep .stu-info-fill-main {
    padding: 0 0 24px 0 !important;
}
// 基础信息-头部
.basic-header,
.basic-header-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-bottom: 20px;
    .basic-header-flag {
        width: 1px;
        height: 16px;
        background: #bfbfbf;
        margin: 0 16px 0 12px;
    }
    .back {
        font-size: 14px;
        margin-left: 0;
        color: #3c7fff;
    }

    .basic-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .exportBtn {
        margin-left: 0;
    }
}
.basic-header-view {
    width: calc(100% - 20px);
    position: absolute;
    top: 0px;
    left: 0px;
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #e8e8e8;
}
// 基础信息-表单
.basic-form,
.basic-form-view {
    display: flex;
    flex-direction: row;
    // align-items: center;
    padding: 56px 50px 40px 0px;

    .basic-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;

        .basic-avatar-div {
            width: 104px;
            height: 104px;
        }

        .basic-avatar-img img {
            width: 100%;
            height: 100%;
        }

        .basic-avatar-upbtn {
            border: 1px dashed #dbdbdb;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            box-sizing: border-box;
            cursor: pointer;

            img {
                width: 52px;
                height: 58px;
            }

            div {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                border-radius: 0px 0px 4px 4px;
                background: linear-gradient(0deg, #f2f2f2 0%, #ffffff 100%);
                width: 100%;
                padding: 8px 0;
                text-align: center;
            }
        }

        .basic-avatar-tip {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bfbfbf;
            margin-top: 10px;
        }
    }

    .basic-info {
        flex: 1;
    }
}
.basic-form-view {
    padding: 0;
}
// 基础信息-按钮
.basic-btn-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .btn-confirm {
        margin-left: 16px;
    }
}
</style>

<style lang="scss" scoped>
/deep/ .el-table--border,
.el-table--group {
    border: 1px solid #919599 !important;
}
/deep/ .el-table th.el-table__cell {
    color: #363b40 !important;
    font-size: 14px;
    font-weight: 400;
    background-color: #edf4fa !important;
    border: 1px solid #919599 !important;
    border-top: 1px solid rgba(255, 255, 255, 0) !important;
    &:nth-child(n) {
        border-left: 1px solid rgba(255, 255, 255, 0) !important;
    }
}
/deep/ .el-table__body td.el-table__cell {
    border: 1px solid #919599 !important;
    border-top: 1px solid rgba(255, 255, 255, 0) !important ;
    font-size: 14px;
    color: #363b40;
    background-color: #edf4fa;
    width: 166px !important;
}
.el-form-item__content {
    border-left: 1px solid #919599 !important;
    margin-left: 166px !important;
    .explain {
        text-align: center !important;
        font-size: 14px !important;
        color: #363b40 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    // .el-upload--picture-card{
    //   margin-top: 14px;
    // }
    .avatar-uploader-icon {
        width: 148px !important;
        height: 148px !important;
        line-height: 148px !important;
    }
    .avatar-uploader {
        margin-top: 21px !important;
        // overflow: unset !important;
    }
    .el-upload {
        cursor: not-allowed !important;
    }
}

/deep/ .el-form-item {
    border: 1px solid #919599 !important;
    .explainText {
        display: none;
    }
    .upload-demo {
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .el-input.is-disabled .el-input__inner {
        background-color: white !important;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #363b40;
        // height: 100%;
    }
    .el-checkbox-group {
        box-sizing: border-box;
        overflow-y: scroll;
    }
    .el-radio-group {
        width: 100%;
        // padding-top: 10px;
        line-height: 47px !important;
        height: 36px;
        overflow-y: scroll;
    }
    .el-textarea.is-disabled .el-textarea__inner {
        background-color: white !important;
        font-size: 14px;
        color: #363b40;
    }
    .el-form-item__label {
        text-align: center;
        text-align-last: unset;
        font-size: 14px;
        color: #363b40;
        background-color: #edf4fa;
        width: 166px !important;
    }
    .el-form-item__content {
        border-left: 1px solid #919599 !important;
        margin-left: 166px !important;
        .explain {
            text-align: center !important;
            font-size: 14px !important;
            color: #363b40 !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        // .el-upload--picture-card{
        //   margin-top: 14px;
        // }
        .avatar-uploader-icon {
            width: 148px !important;
            height: 148px !important;
            line-height: 148px !important;
        }
        .avatar-uploader {
            margin-top: 21px !important;
            // overflow: unset !important;
        }
        .el-upload {
            cursor: not-allowed !important;
        }
    }
}

.archivesCompleteness {
    width: 100%;
    height: 40px;
    line-height: 40px;
    // border: 1px solid black;
    background: #edf4fa;
    margin-top: 32px;
    display: flex;
    align-items: center;

    .archivesCompleteness-icon {
        display: inline-block;
        margin-left: 12px;
        width: 13px;
        height: 8px;
    }

    .archivesCompleteness-title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6c84a6;
        margin: 0 10px;
    }
    .archivesCompleteness-value {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3c7fff;
    }
}

// 公共-有分割线的标题
::v-deep .line-title {
    text-align: center;
    display: flex;
    flex-direction: row;
}
::v-deep .line-title:before {
    content: "";
    flex: 1 1;
    width: 400px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}
::v-deep .line-title:after {
    content: "";
    flex: 1 1;
    width: 400px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}
// 公共-按钮
.btn {
    width: 88px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}
.btn-reset {
    background: #ebf1f7;
    color: #737373;
}
.btn-confirm {
    background: #3c7fff;
    color: #ffffff;
}

// 展示或收起按钮
.show-stow-btn {
    margin-top: 10px;
    height: 48px;
    background: #f7fafc;
    border-radius: 6px 6px 0px 0px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3c7fff;
    cursor: pointer;

    img {
        width: 12px;
        height: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
    }
}

// 更多信息表
.detail-form {
    background-color: #ffffff;
    border-radius: 0px 0px 6px 6px;
    // flex: 1;

    ::v-deep .stu-info-fill {
        .el-form-item.is-required::before {
            display: none;
        }
    }
}

// 加边框、两端对齐及相关修改
::v-deep .el-form-item {
    margin-bottom: 0;
    border: 1px solid black;

    .el-form-item__label {
        // text-align-last: justify; // 最后一行两端对齐
        // text-align: justify; // 两端对齐
        padding: 0 12px;
        // box-sizing: border-box;
        // background-color:#edf4fa;
    }

    .el-form-item__content {
        border-left: 1px solid black;
        min-height: 36px;
        position: relative;

        .form-value-show-only,
        .el-radio,
        .el-checkbox-group,
        .explain {
            padding-left: 15px;
        }
        .el-checkbox-group {
            height: 36px;
        }
        .el-radio-group {
            height: 36px;
        }
        .upload-demo {
            padding-left: 5px;
        }

        .avatar-uploader {
            padding: 5px 10px;
        }

        .input-div {
            position: absolute;
            height: 100%;
            .input {
                .el-input__inner {
                    height: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 100%;
                    text-align: center;
                }
            }
        }
    }
}
// label两端对齐
::v-deep
    .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
::v-deep
    .el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label:before {
    content: unset;
}
::v-deep .el-form-item.is-required {
    position: relative;
}
::v-deep .el-form-item.is-required::before {
    position: absolute;
    content: "*";
    color: #f56c6c;
    top: 5px;
    left: 5px;
}
// 组件组
::v-deep .comps-group-div {
    margin-top: 22px;
    .comps-group-item {
        padding: 0;
    }
}
// 基础信息-标题
.basic-title {
    margin-left: 144px;
}
// 基础信息-按钮
.basic-btn-div {
    margin-left: 193px;
}
.baseCol {
    border: 1px solid #919599;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #363b40;
    min-height: 42px;
    // padding: 0 12px;
    box-sizing: border-box;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.baseCol-student {
    border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-sex {
    border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-id {
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-idCard {
    border-top: 1px solid rgba(255, 255, 255, 0);
    border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-studyNo {
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-img {
    height: 126px;
    line-height: 126px;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-user {
    height: 124px;
    border: 1px solid #919599;
    border-left: 1px solid rgba(255, 255, 255, 0);
    img {
        display: block;
        margin: 10px auto 0;
        width: 104px;
        height: 104px;
        border-radius: 8px;
    }
}
.baseCol-checked {
    background-color: #edf4fa;
}
.view-title {
    width: 100%;
    height: 14px;
    line-height: 14px;
    display: flex;
    margin: 42px 0 16px;

    .view-flag {
        width: 3px;
        height: 14px;
        background-color: #3c7fff;
        margin-right: 8px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
    }
}

.el-icon-view {
    position: relative;
    cursor: pointer;
    margin-left: 10px;
}

.el-icon-view.is-closed::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #606266;
    transform: rotate(-45deg);
    top: -1px;
    left: 50%;
}

::v-deep .el-input__inner::placeholder {
    color: white !important;
}
::v-deep .el-textarea__inner::placeholder {
    color: white !important;
}
.showImage{
    width:104px;
    height:104px;
    display: block;
    margin: 10px auto;
}
</style>
