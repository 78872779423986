<template>
    <div class="achievement-list">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :formData="formData"
                        marginBottom="0px"
                        @clickBtn="clickBtn"
                        class="expand-filter-wrapper"
                    ></expand-filter>
                </div>

                <table-data
                    :config="table_config"
                    :tableData="table_data"
                    v-loading="loadingTable"
                    backgroundHeader="#FAFBFC"
                    :headerStyle="{height: '50px'}"
                    :rowStyle="{height: '50px'}"
                    ref="table"
                    id="table"
                >
                    <template v-slot:operation="slotData">
                        <el-button
                            class="primary"
                            @click="handleDetails(slotData.data)"
                            >详情
                        </el-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getTableList"
                />
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {FilterData, Loading, Pagination} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
// import ExpandFilter from "../Sub/ExpandFilter";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import {SalaryProjectModel} from "@/models/SalaryProject.js";
import onResize from "@/mixins/onResize";
// 逻辑运算
export default {
    mixins: [onResize],
    name: "AchievementList",
    components: {
        FilterData,
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
    },
    props: {
        routerReturn: {
            type: String,
            default: "",
        },
        basicForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
        activeItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                examName: "",
                schoolId: "",
                onlyCode: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入考试名称",
                        key: "examName",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        // auth: ["query:list"],
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "考试名称",
                        prop: "examName",
                        align: "center",
                    },
                    {
                        label: "学期",
                        // prop: "semester",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return row.semester == "1" ? "上学期" : "下学期";
                        },
                    },
                    {
                        label: "考试类型",
                        prop: "examTypeName",
                        align: "center",
                    },
                    {
                        label: "科目",
                        prop: "examSubjects",
                        align: "center",
                    },
                    {
                        label: "成绩",
                        prop: "totalScore",
                        align: "center",
                    },
                    {
                        label: "年级排名",
                        prop: "totalGradeRank",
                        align: "center",
                    },
                    {
                        label: "班级排名",
                        prop: "totalClassRank",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                    },
                ],
                check: false,
                showIndex: false,
                height: "",
            },
            table_data: [],
            detailData: {
                title: "",
                list: [],
            },
            PriceState: false, //   薪资状态
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    mounted() {},
    methods: {
        /**
         * @Description: 获取列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-19 10:26:08
         */
        getList(isPaging) {
            this.loadingTable = true;
            let data = {};
            if (!isPaging) {
                this.listQuery.pageNum = 1;
            }
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            this.listQuery.schoolId = this.$store.state.schoolId;
            this.listQuery.onlyCode = this.basicForm.onlyCode;
            this._fet(
                "/school/schoolArchivesData/getStudentAchievement",
                this.listQuery,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                        this.$nextTick(() => {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch((err) => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 查询、新增类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:19:28
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 分页处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-11 17:18:38
         */
        getTableList(lime) {
            console.log(lime, "lime");
            this.listQuery.pageNum = lime.page;
            this.listQuery.pageRow = lime.limit;
            this.getList(1);
        },
        /**
         * @Description: 详情页面跳转
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-19 16:44:34
         */
        handleDetails(data) {
            this.$router.push({
                name: "ClassManageAnalysis",
                params: {
                    onlyCode: this.basicForm.onlyCode,
                    source: "AchievementList",
                    examId: data.id,
                    gradeId: data.gradeId,
                    activeItem: JSON.stringify(this.activeItem),
                    id:data.id,
                    examName:data.examName
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.achievement-list {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
    // position: relative;
    // margin: 0 auto;
    width: 920px !important;
}
.expand-filter-wrapper {
    ::v-deep .el-input__inner::placeholder {
        color: #C8CCD3 !important;
    }
}
.filter-wrap {
    display: flex;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: hidden;
    margin: 8px 0 10px 0;
    border-radius: 4px 0 0 4px;
    .filter-right-button {
        border-color: transparent;
    }

    .expand-filter {
        padding: 0;

        .date-picker-wrap {
            display: flex;
            align-items: center;

            .date-icon {
                margin-left: 10px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                background: #d4d6d9;
                border-radius: 50%;
                color: #ffffff;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
}
.setIcon {
    display: flex;
    align-items: center;
    .img {
        width: 14px;
        height: 12px;
        margin-right: 7px;
        float: none;
    }
}
.box {
    padding: 0px 8px;
    .box_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
        padding-bottom: 15px;
    }
    .box_money {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f2;
        .tip {
            color: #6d7073;
        }
    }
    .item_list {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        display: flex;
        line-height: 50px;
        justify-content: space-between;
        border-bottom: 1px solid #edf0f2;
        .name {
            color: #2b2f33;
        }
        .money {
            color: #6d7073;
        }
    }
}
.boxDialog {
    /deep/.el-dialog {
        // height: 600px;
        max-height: 600px;
    }
}
input::placeholder {
    color: C8CCD3;
    font-size: 14px;
    font-style: italic;
}
</style>
